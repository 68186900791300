/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';

@import 'custom/index';

.mapboxgl-popup-content {
	padding: 0 3px !important;
}
.distributorPopup {
	.popupContainer {
		display: flex !important;
		justify-content: space-between;
		.status {
			font-size: 20px !important;

			&.status-available {
				color: green;
			}
			&.status-assigned {
				color: yellow;
			}
			&.status-store_arrival {
				color: orange;
			}
			&.status.status-in_transit {
				color: red;
			}
		}
	}
	h5.map-username {
		font-size: 20px !important;
		margin: 0 !important;
	}

	min-width: 140px;
}
.swal2-popup.swal2-modal.popup-wide {
	min-width: 720px;
}
.orderDetailsPopupRow {
	display: flex;
	justify-content: center;
	width: 100%;
}
.orderDetailsPopupColumn {
	margin: 10px 20px;
	width: 50%;
	text-align: left;
}
@media(max-width:768px){
	.swal2-popup.swal2-modal.popup-wide {
		min-width: 100%;
	}
	.orderDetailsPopupRow {
		flex-direction: column;
	}
	.orderDetailsPopupColumn {
		width: 100%;
	}
}
.asside-caret {
	background: #000000;
	cursor: pointer;
	width: 20px;
	height: 30px;
	position: absolute;
	top: 16px;
	left: 0;
	display: flex;
	align-items: center;
	border-radius: 0px 10px 10px 0;
	z-index: 999999;
	color: white;
	transition: left 0.2s ease-in-out;
}
.asside-caret.open {
	transform: rotate(180deg);
	border-radius: 10px 0 0 10px;
	left: 15rem;
}
.aside-body {
	margin-top: 0;
	max-height: 80%;
}
@media (max-width: 1280px) {
	.distributorCards .card-body {
		padding: 0;
		word-break: break-word;
	}
	.distributorCards .card {
		min-width: 100px;
		max-width: 110px;
	}
}
.right-click-item {
	padding: 7px 0;
	border-bottom: 1px solid #eee;
}
.right-click-item:last-of-type {
	border: 0;
}
.distributorCards {
	.card {
		max-width: 170px;
		min-width: 170px;
		max-height: 80px;
		margin-bottom: 0px;
		.card-body {
			padding: 5px 5px;
			p {
				font-size: 15px;
				margin: 0;
			}
			p:nth-of-type(2) {
				padding-right: 30px;
			}
		}
	}
}
.addAddressModalSwal {
	border-radius: 10px;
	min-width: 60vw !important;
	min-height: 500px;
}
.addAddressModalSwal {
	padding: 0 !important;
	.card {
		margin: 0;
		border-radius: 0;
	}
}
@media (max-width: 1024px) {
	.addAddressModalSwal {
		min-width: 860px !important;
	}
}
@media (max-width: 768px) {
	.addAddressModalSwal {
		min-width: 100% !important;
	}
}
.inputLoadingBar {
	width: 100%;
	margin: 0 auto;
	border-radius: 10px;
	border: 4px solid transparent;
	position: relative;
	padding: 1px;
}
.inputLoadingBar:before {
	content: '';
	border: 1px solid #fff;
	border-radius: 10px;
	position: absolute;
	top: -4px;
	right: -4px;
	bottom: -4px;
	left: -4px;
}
.inputLoadingBar .loaderBar {
	position: absolute;
	border-radius: 10px;
	top: 0;
	right: 100%;
	height: 4px;
	left: 0;
	background: #90a0fc;
	width: 0;
	animation: webtitansLoadingBar 1s linear infinite;
}
ul.addressQueryResultsList {
	background: white;
	list-style: none;
	text-align: start;
	margin: 0 10px;
	position: absolute;
	width: calc(100% - 20px);
	z-index: 9999;
	border-radius: 10px;
	-webkit-box-shadow: 0px 13px 16px 0px rgb(0 0 0 / 34%);
	-moz-box-shadow: 0px 13px 16px 0px rgb(0 0 0 / 34%);
	box-shadow: 0px 13px 16px 0px rgb(0 0 0 / 34%);
	li {
		margin: 0;
		padding: 5px;
		border-bottom: 1px solid #eee;
		cursor: pointer;
	}
}
@keyframes webtitansLoadingBar {
	0% {
		left: 0%;
		right: 100%;
		width: 0%;
	}
	10% {
		left: 0%;
		right: 75%;
		width: 25%;
	}
	90% {
		right: 0%;
		left: 75%;
		width: 25%;
	}
	100% {
		left: 100%;
		right: 0%;
		width: 0%;
	}
}
// .aside-logout{
//     padding: .375rem 1.75rem;
//     cursor: pointer;
// }
