@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Orbitron:wght@400..900&display=swap');

.table > :not(caption) > * > * {
	background-color: transparent !important;
}

body {
	/* font-size: 1.15rem !important; */
}

#aside-demo-pages,
#aside-menu,
.navigation-line {
	display: none;
}

table.cashier-table tbody tr td {
	white-space: nowrap;
}

@keyframes flash {
	0% {
		background-color: red;
	}
	50% {
		background-color: transparent;
	}
	100% {
		background-color: red;
	}
}

@keyframes flash-yellow {
	0% {
		background-color: rgb(251, 255, 0);
	}
	50% {
		background-color: transparent;
	}
	100% {
		background-color: rgb(251, 255, 0);
	}
}

/* Apply the flashing animation */
.flashing {
	animation: flash 1s infinite;
}

.flashing-yellow {
	animation: flash-yellow 1s infinite;
}

.flashing-yellow td,
.flashing td {
	color: #000 !important;
}

.mapboxgl-popup-content {
	border: 2px solid;
}
/* .scooter-marker {
	background-image: url('https://api.webtitans.gr/transporters-map/wolt_scooter.webp');
	background-size: cover;
	width: 45px;
	height: 45px;
	cursor: pointer;
} */
.scooter-marker {
	background: red;
	border: 6px solid red;
	width: 13px;
	height: 13px;
	cursor: pointer;
	border-radius: 50%;
}
.scooter-marker:after {
	content: '';
	position: absolute;
	border-radius: 50%;
	border: 2px solid red;
	padding: 8px;
	top: -9.3px;
	left: -9.3px;
}
/* .scooter-marker-available {
	background-image: url('https://api.webtitans.gr/transporters-map/wolt_scooter_green.webp');
} */
.scooter-marker.scooter-marker-available {
	border: 6px solid #20ff20;
	background: #20ff20;
}
.scooter-marker.scooter-marker-available:after {
	border: 2px solid #20ff20;
}
.scooter-marker-pending,
.scooter-marker-assigned {
	/* background-image: url('https://api.webtitans.gr/transporters-map/wolt_scooter_yellow.webp'); */
	border: 6px solid #ff9800;
	background: #ff9800;
}
.scooter-marker-pending:after,
.scooter-marker-assigned:after {
	border: 2px solid #ffce40;
}
.scooter-marker-store_arrival,
.scooter-marker-in_transit {
	/* background-image: url('https://api.webtitans.gr/transporters-map/wolt_scooter.webp'); */
}

.scooter-marker-break {
	border: 6px solid #6b5dd3;
	background: #6b5dd3;
}
.scooter-marker-break:after {
	border: 2px solid #6b5dd3;
}
.store-marker {
	background-image: url('https://api.webtitans.gr/transporters-map/wolt_store.webp');
	background-size: cover;
	width: 38px;
	height: 44px;
	cursor: pointer;
}

.map-legend {
	padding: 0.5em;
	width: 400px;
	height: 300px;
	position: absolute;
	right: 19px;
	bottom: 38px;
	background: white;
	z-index: 9999999999999;
	display: flex;
	flex-direction: column;
}
.legend-header {
	width: 100%;
	min-height: 25px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0.5em 1em;
}
.legend-body {
	display: flex;
	justify-content: space-between;
	overflow-y: hidden;
}
.legend-column {
	width: 50%;
	border-right: 1px solid #eee;
	padding: 0.5em;
}
.legend-column ul {
	list-style: none;
	overflow-y: scroll;
	padding: 0;
}
.legend-column ul li {
	margin-top: 5px;
	cursor: pointer;
}
.legend-column img.distributor-scooter {
	max-width: 35px;
}
.legend-mobile-only {
	display: none;
}
@media (max-width: 490px) {
	.legend-mobile-only {
		display: block;
	}
	.map-legend {
		width: 100%;
		transition: all 0.3s ease-in-out;
		height: auto;
		min-height: 300px;
		right: 0;
		bottom: 0;
	}
	.map-legend.collapsed {
		bottom: -160px;
	}
}

/* CHAT */
.transporters-chat-icon {
	position: fixed;
	width: 40px;
	height: 40px;
	bottom: 50px;
	right: 30px;
	z-index: 1000;
	cursor: pointer;
	background: url('./chat-icon.webp');
	background-size: cover;
	color: white;
	border-radius: 50%;
	padding: 10px 15px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
}
.transporters-chat-wrapper {
	position: fixed;
	bottom: 70px;
	right: 20px;
	width: 410px;
	height: 590px;
	background-color: white;
	border-radius: 8px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
	z-index: 1000;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.transporters-chat-header {
	padding: 5px;
	background-color: rgb(0, 123, 255);
	color: white;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	max-height: 30px;
}
.trannsporters-chat-inner {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	height: 93%;
}
.transporters-chat-messages {
	overflow-y: scroll !important;
	padding: 5px;
}
.transporters-message-bar {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: 1px #aeaeae solid;
	border-radius: 2px;
}
.transporters-message-bar textarea {
	width: 100%;
	height: 100%;
	border: 0;
	padding: 5px;
	font-size: 15px;
}
.transporters-message-bar button {
	border: 0;
	padding: 13px;
}
.chatNewMsg {
	width: 12px;
	height: 12px;
	background: red;
	border-radius: 50%;
	position: absolute;
	top: -3px;
	right: 2px;
}
.msgBubble {
	display: flex;
	margin-bottom: 4px;
	justify-content: space-between;
}
.msgBubble.self {
	flex-direction: row-reverse;
}
.msgBubble.self .text {
	background: #007bff;
}
.msgBubble .text {
	background: #46bcaa;
	color: white;
	padding: 3px 11px;
	border-radius: 10px;
	max-width: 80%;
}
.chatNewMsg.user {
	position: relative;
	width: 6px !important;
	height: 6px !important;
	padding: 0;
	top: -13px;
	left: 0;
}
.chatNewMsg.userType {
	position: relative;
	padding: 0;
	width: 7px;
	height: 7px;
	top: -12px;
	right: -10px;
	margin-bottom: -10px;
}
.livechat-users-list {
	max-width: 100%;
}
.livechat-users-list li {
	border-bottom: 1px solid #eee;
	margin: 0 !important;
	padding: 0.5em 0.4em;
}
.livechat-users-list li:before {
	content: '> ';
}
.chat-btn-send {
	background-color: transparent;
	background: url('./send-icon.png');
	background-size: 20px;
	background-repeat: no-repeat;
}
.chat-btn-record {
	background-color: transparent;
	background: url('./record.webp');
	background-size: 20px;
	background-repeat: no-repeat;
}
.chat-btn-stop-record {
	background-color: transparent;
	background: url('./stop-record.png');
	background-size: 20px;
	background-repeat: no-repeat;
}
.chat-btn-del-record {
	background-color: transparent;
	background: url('./trash.png');
	background-size: 20px;
	background-repeat: no-repeat;
}
/* END OF CHAT */

a.locationPopup {
	cursor: pointer;
}
.shiftMap {
	z-index: 9999999999;
	max-width: 450px;
	overflow: hidden;
	background: white;
	padding: 1em;
	border-radius: 10px;
	position: absolute;
	top: 35px;
	box-shadow: 5px 6px 17px -3px rgba(0, 0, 0, 0.75);
	margin-bottom: 20px;
}

aside.aside {
	display: none;
}

aside.aside.open {
	display: block;
}

table.orders-table tr td,
table.orders-table tr th {
	white-space: nowrap;
}

@media (max-width: 768px) {
	.table-modern > tbody > tr > :first-child,
	.table-modern > thead > tr > :first-child,
	.table-modern > tbody > tr > :last-child,
	.table-modern > thead > tr > :last-child {
		border-radius: 0;
	}
	table.orders-table tr td,
	table.orders-table tr th {
		padding: 0px;
		padding-right: 5px;
		font-size: 11px;
		white-space: wrap;
	}

	table.orders-table tr th {
		font-weight: 600;
	}

	table.orders-table tr th:nth-child(1) {
		max-width: 15vw;
		min-width: 15vw;
	}

	table.orders-table tr th:nth-child(2) {
		max-width: 15vw;
		min-width: 15vw;
	}

	table.orders-table tr th:nth-child(3) {
		max-width: 20vw;
		min-width: 20vw;
	}

	table.orders-table tr th:nth-child(4) {
		max-width: 20vw;
		min-width: 20vw;
	}

	table.orders-table tr th:nth-child(5) {
		max-width: 20vw;
		min-width: 20vw;
	}

	table.orders-table tr th:nth-child(6) {
		max-width: 10vw;
		min-width: 10vw;
	}
}

label.label-black {
	color: black;
}

label.add-new-order {
	flex-basis: 125px; /* Ensures the label takes exactly 300px */
	min-width: 170px; /* Prevents shrinking below 300px */
	max-width: 125px; /* Prevents growing beyond 300px */
	font-weight: 400;
	font-size: 16px;
}

@media (max-width: 768px) {
	label.add-new-order {
		flex-basis: 80px; /* Adjust width for smaller screens */
		min-width: 80px; /* Prevent shrinking below 100px */
		max-width: 80px; /* Prevent growing beyond 100px */
		font-size: 12px; /* Optionally reduce font size */
	}

	.page {
		padding: 0px !important;
	}

	div.card {
		box-shadow: none;
	}

	.container,
	.container-fluid,
	.container-xxl,
	.container-xl,
	.container-lg,
	.container-md,
	.container-sm {
		padding: 0 !important;
	}
}

label.add-new-order-must {
	font-weight: 800;
}

/** Allages Dhmhtrh*/
@media (min-width: 1024px) {
	.add-new-order-must {
		color: #0d7c00 !important;
		font-size: 20px !important;
	}
	.add-order-container .form-check.form-switch label {
		font-size: 17px;
	}
}
@media (max-width: 768px) {
	.add-new-order-must {
		color: #0d7c00 !important;
	}
	.add-new-order-must:after {
		content: '';
		position: absolute;
		top: 10px;
		left: -11px;
		width: 7px;
		height: 7px;
		background: red;
		border-radius: 50px;
	}
	.add-order-container input,
	.add-order-container select,
	.select__value-container {
		font-size: 20px;
	}
	.parodos-input {
		padding: 10px 4px !important;
	}
	.parodos-buttons {
		top: 1px !important;
		right: 0 !important;
	}
	.add-order-container .form-check.form-switch label {
		font-size: 17px;
	}
	.form-check.form-switch .form-check-input {
		margin-left: -30px !important;
	}
	.transporters-chat-wrapper {
		max-width: 350px;
	}
}
/** END Allages Dhmhtrh*/
.add-new-order-select {
	border-radius: 0px;
	border: 1px solid #ccc;
}

.form-control {
	border-radius: 0px !important;
	border: 1px solid #ccc;
	padding: 5px;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
	/* padding: 0px; */
}

div.card.order-card {
	border-radius: 0px;
}

.break-container .swal2-popup,
.break-container .swal2-popup .swal2-html-container {
	min-width: 60vw;
	min-height: 60vh;
}
@media (max-width: 768px) {
	.break-container .swal2-popup,
	.break-container .swal2-popup .swal2-html-container {
		min-width: 90vw;
		min-height: 80vh;
	}
}

table.orders-table-mobile-desing thead tr {
	background: #2b2b2b;
	line-height: 1em;
}

table.orders-table-mobile-desing tbody tr {
	background: #000;
	line-height: 1em;
}

table.orders-table-mobile-desing thead tr th {
	font-weight: 800;
	color: #fff;
}

table.orders-table-mobile-desing tbody tr td {
	color: #fff;
	font-weight: 800;
}

.subheader {
	box-shadow: none !important;
}

.dashboard-container {
	padding: 0px;
}

table.orders-table-desktop thead tr {
	background: #5f5f5f;
	color: #fff;
}

table.orders-table-desktop thead tr th {
	color: #fff;
	border-radius: 0px !important;
}

table.orders-table-desktop tbody tr td,
table.orders-table-desktop thead tr th {
	font-weight: 800;
}

.table-modern > tbody > tr > *,
.table-modern > thead > tr > * {
	padding: 0.5rem !important;
	font-size: 1.25rem;
}

table.orders-table-desktop-desing tbody tr {
	line-height: 1em;
}

.table-modern > tbody > tr > :first-child,
.table-modern > thead > tr > :first-child,
.table-modern > tbody > tr > :last-child,
.table-modern > thead > tr > :last-child {
	border-radius: 0px;
}

div.mobile-dashboard-page {
	justify-content: space-between;
}
